// Constants of known feature flags. These should directly reflect those in scalar/featureflag.go

export const PROJECT_COMPS = 'PROJECT_COMPS';
export const FORECASTED_COST_REPORT = 'FORECASTED_COST_REPORT';
export const YC_TIMELINE_DEFAULT_ZOOM = 'YC_TIMELINE_DEFAULT_ZOOM';
export const YC_INSIGHTS_V2 = 'YC_INSIGHTS_V2';
export const YC_DEMO_MOCKS_INSIGHTS_V2 = 'YC_DEMO_MOCKS_INSIGHTS_V2';
export const DD_PROJECT_IDEAS = 'DD_PROJECT_IDEAS';
export const DD_TIMELINE_V2 = 'DD_TIMELINE_V2';
export const NS_OWNER_COSTS_V2 = 'NS_OWNER_COSTS_V2';
export const CORE_INSIGHTS_ITEMS_STATUS = 'CORE_INSIGHTS_ITEMS_STATUS';
export const PROJ_OWNER_CONTINGENCY = 'PROJ_OWNER_CONTINGENCY';
export const PROJ_CONTINGENCY_ADDS = 'PROJ_CONTINGENCY_ADDS';
export const TS_ITEM_SMART_CATZ = 'TS_ITEM_SMART_CATZ';
export const CORE_RISKS_LAUNCH = 'CORE_RISKS_LAUNCH';
export const PROJ_INHERITED_CONTINGENCY_COSTS = 'PROJ_INHERITED_CONTINGENCY_COSTS';
export const PROJ_REPORTING = 'PROJ_REPORTING';
